import React, { Component } from 'react'

export default class Header extends Component {
  render() {
    return (
      <>
        <div className="header">
          Paul Stinchcombe
          <p className="subtitle">Software Development Consultant</p>
        </div>
      </>
    )
  }
}
