import React from 'react'
import './App.css'
import Content from './Components/Content'
import Header from './Components/Header'
import Photo from './Components/Photo'

function App() {
  return (
    <>
      <div className="App">
        <Header />
        <Photo />
        <Content />
      </div>
    </>
  )
}

export default App
