import React from 'react'

export default function Content() {
  return (
    <>
      <div className="content">
        <p style={{ fontWeight: '600', fontSize: '22pt' }}>About Me</p>
        <p>
          Born in the United Kingdom in 1963 and started programming at the age
          of 14. I started my professional career at the age of 20 (1983),
          working for Chemical Bank in Cardiff. In 1986 I moved to London and
          worked in retail (Harrods), and the betting industry (William Hill),
          before moving to manufacturing in Bristol. After a few years I started
          working in the telecomunications industry, moving back to London
          working with high-capacity IVR systems (Ladbrokes Racing).
        </p>
        <p>
          In 1994 I moved to Kuala Lumpur (Malaysia) working as a consoltant
          helping to setup a company specialising in IVR and call center
          solutions.
        </p>
        <p>
          I moved back to th UK in 1997 to work with Rockwell-Collins
          (Avionics), but in 1998 had the opportunity to move back to Malaysia
          and start a company specialising in premium-rate IVR systems. In 2001
          I moved to Montreal (Canada) to work on a new form of AI (utilising
          genetic algorithms), returning to Malaysia in 2002 on a 2 year
          contract with Advanced BusinessLink as head of R & D. I then worked
          for several marketing companies as technology head until 1997 when I
          partnered with some like-minded people to start Dragonsoft Research.
          After a buy-out of Dragonsoft in 2019 I started working for Wiseyes
          Solutions as their Chief Technology Innovations Officer.
        </p>
        <p>
          In July 2022 I joined Manyfold Pte Ltd as CTO. Manyfold is a startup
          focussed on NFT rental. <br />
          My responsibilities include management of an outsourced project team
          (based in Vietnam) to deliver our primary web and mobile applications.
          Development and deployment of blockchain smart contracts for the
          rental application, and AMM/Treasury contracts for the tokenomics. I
          also developed a NFT player application for Android TV as a
          complimentary product, integrating with the rental applications to
          provide a managed display environment for NFTs.
        </p>
        <p className="resume">
          <a href="/resume.pdf">See my resume</a>
        </p>
      </div>
    </>
  )
}
