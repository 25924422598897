import React, { Component } from 'react'

export default class Photo extends Component {
  render() {
    return (
      <div className="photo">
        <img src="/img/Paul.jpg" alt="My profile"></img>
      </div>
    )
  }
}
